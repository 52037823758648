import React, { useState, useEffect, useContext, useCallback } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { green, red } from "@material-ui/core/colors";
import Loader from "react-loader-spinner";
import app from "../../FirebaseInit";
import Nav from "../Nav.js";
import { useAlert } from "react-alert";
import { AuthContext } from "../../Auth";
import { useHistory } from "react-router-dom";
import CoFormA from "../forms/Co-FormA";
import CoFormB from "../forms/Co-FormB";
import * as firebase from "firebase/app";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    flexGrow: "2",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
const ColorButton2 = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default function PendingCOOForm() {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const alert = useAlert();
  const { userProfile } = useContext(AuthContext);
  let query = useQuery();
  var id = query.get("id");
  let history = useHistory();

  useEffect(() => {
    app
      .firestore()
      .collection("COOForms")
      .doc(id)
      .get()
      .then((snap) => {
        if (snap.data().status == "pending") setData(snap.data());
        else history.push("/");
      });
  }, []);

  const onApprove = useCallback(async (e) => {
    e.preventDefault();
    if (userProfile.type != "admin")
      return alert.error("You are not authorized to approve");
    try {
      await app.firestore().collection("COOForms").doc(id).update({
        status: "approved",
        ApprovedDate: firebase.default.firestore.FieldValue.serverTimestamp(),
        ApprovedBy: userProfile.id,
      });
      alert.success("Approved");
      history.push("/");
    } catch (e) {
      console.log(e.message);
      alert.error(e.message);
    }
  });

  const onReject = useCallback(async (e) => {
    e.preventDefault();
    if (userProfile.type != "admin")
      return alert.error("You are not authorized to approve");
    var reason = prompt("Enter reason for rejection");
    if (reason.length == 0) return alert.error("Reject reason cannot be empty");
    try {
      await app.firestore().collection("COOForms").doc(id).update({
        status: "rejected",
        rejectReason: reason,
        rejectedDate: firebase.default.firestore.FieldValue.serverTimestamp(),
        rejectedBy: userProfile.email,
      });
      alert.success("Rejected");
      history.push("/");
    } catch (e) {
      console.log(e.message);
      alert.error(e.message);
    }
  });

  if (data == null)
    return (
      <center>
        <Loader type="ThreeDots" color="Black" width="50" height="50" />
      </center>
    );

  return (
    <div>
      <Nav />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <CoFormA data={data} disabled={true} />
          <CoFormB data={data} disabled={true} formAData={data} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <ColorButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onApprove}
              >
                Approve
              </ColorButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <ColorButton2
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onReject}
                className={classes.submit}
              >
                Reject
              </ColorButton2>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
}
