import React, { useContext, useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Title from "./Title";
import { AdminNav } from "./AdminNav";
import { orange } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { useAlert } from "react-alert";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import app from "../../FirebaseInit";
import { AuthContext } from "../../Auth";
import UserList from "./formables/UserList";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700],
    },
  },
}))(Button);

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Jager Squard
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    backgroundColor: "black",
    color: "orange",
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    backgroundColor: "orange",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    color: "Orange",
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    color: "Orange",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    backgroundColor: "#eeeeee",
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  button: {
    margin: theme.spacing(2, 0, 2),
    width: "50%",
  },
}));

export default function Settings() {
  const alert = useAlert();
  const classes = useStyles();
  const { userProfile, prices } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };
  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const sendPasswordResetEmail = async (e) => {
    try {
      await app.auth().sendPasswordResetEmail(userProfile.email);
      alert.success("Password reset link sent !");
    } catch (err) {
      console.log(err.message);
      alert.error(err.message);
    }
  };

  const changeCOOPrice = async (e) => {
    try {
      e.preventDefault();
      const { price } = e.target.elements;
      handleDialogClose();
      if (!userProfile?.changeCOOPrice)
        return alert.error(
          "You don not have permission to perform this action"
        );
      if (price.value <= 0) return alert.error("Invalid Price");
      await app
        .firestore()
        .collection("settings")
        .doc("prices")
        .update({ coo: price.value });
      alert.success("COO price changed !");
    } catch (err) {
      console.log(err.message);
      alert.error(err.message);
    }
  };

  const addAdmin = async (e) => {
    try {
      e.preventDefault();
      const {
        email,
        password,
        userName,
        addRemoveAdmins,
        approveForms,
        changeCOOPrice,
        disableUser,
        promoteUser,
        viewPayments,
      } = e.target.elements;
      if (password.value.length < 6)
        return alert.error("Password should be at least 6 characters!");
      handleAddDialogClose();
      alert.info("Adding User...");
      const addAdminFunction = app.functions().httpsCallable("addAdmin");
      const result = await addAdminFunction({
        email: email.value,
        userName: userName.value,
        password: password.value,
        addRemoveAdmins: addRemoveAdmins.checked,
        approveForms: approveForms.checked,
        changeCOOPrice: changeCOOPrice.checked,
        disableUser: disableUser.checked,
        promoteUser: promoteUser.checked,
        viewPayments: viewPayments.checked,
      });
      if (result.data.uid) {
        alert.success("Admin Added !");
        return window.location.reload();
      }
      alert.error("User addition failed !");
    } catch (err) {
      console.log(err);
      alert.error("User addition failed !");
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <img
            width="100"
            height="50"
            src={require("../../asserts/ComponentTMP_0-image.jpg")}
          />
          &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Federation of Chambers of Commerce and Industry of Sri Lanka
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{AdminNav}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Pending A table */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <div style={{ height: 1000, width: "100%" }}>
                  <Title>Settings</Title>
                  <Grid item xs={12} sm={6}>
                    <ColorButton
                      onClick={() => sendPasswordResetEmail()}
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.button}
                    >
                      Send Password Reset Email
                    </ColorButton>
                  </Grid>
                  {userProfile?.addRemoveAdmins ? (
                    <div>
                      <UserList userType="admin" />
                      <ColorButton
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        onClick={handleAddDialogOpen}
                      >
                        Add Admin
                      </ColorButton>
                      <Dialog
                        open={addDialogOpen}
                        onClose={handleAddDialogClose}
                        aria-labelledby="form-dialog-title"
                      >
                        <DialogTitle id="form-dialog-title">
                          Add Admin
                        </DialogTitle>
                        <form onSubmit={addAdmin}>
                          <DialogContent>
                            <TextField
                              autoFocus
                              required
                              margin="dense"
                              id="email"
                              label="Email"
                              type="email"
                              fullWidth
                            />
                            <TextField
                              required
                              margin="dense"
                              id="password"
                              label="Password"
                              type="password"
                              fullWidth
                            />
                            <TextField
                              required
                              margin="dense"
                              id="userName"
                              label="Admin Name"
                              fullWidth
                            />
                            <br />
                            <br />
                            <DialogContentText>Permissions</DialogContentText>
                            <FormControlLabel
                              control={<Checkbox id="addRemoveAdmins" />}
                              label="Add / Remove Admins"
                            />
                            <br />
                            <FormControlLabel
                              control={<Checkbox id="approveForms" />}
                              label="Approve Forms"
                            />
                            <br />
                            <FormControlLabel
                              control={<Checkbox id="changeCOOPrice" />}
                              label="Change COO Price"
                            />
                            <br />
                            <FormControlLabel
                              control={<Checkbox id="disableUser" />}
                              label="Disable Users"
                            />
                            <br />
                            <FormControlLabel
                              control={<Checkbox id="promoteUser" />}
                              label="Promote Users"
                            />
                            <br />
                            <FormControlLabel
                              control={<Checkbox id="viewPayments" />}
                              label="View Payments"
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button
                              onClick={handleAddDialogClose}
                              color="primary"
                            >
                              Cancel
                            </Button>
                            <Button type="submit" color="secondary">
                              Ok
                            </Button>
                          </DialogActions>
                        </form>
                      </Dialog>
                    </div>
                  ) : null}
                  {userProfile?.changeCOOPrice ? (
                    <div>
                      <ColorButton onClick={handleDialogOpen}>
                        Change COO Price
                      </ColorButton>
                      <Dialog
                        open={dialogOpen}
                        onClose={handleDialogClose}
                        aria-labelledby="form-dialog-title"
                      >
                        <DialogTitle id="form-dialog-title">
                          Change Coo Price
                        </DialogTitle>
                        <form onSubmit={changeCOOPrice}>
                          <DialogContent>
                            <DialogContentText>
                              Curernt Price LKR : {prices?.coo}
                            </DialogContentText>
                            <TextField
                              autoFocus
                              required
                              margin="dense"
                              id="price"
                              label="Price"
                              type="number"
                              fullWidth
                            />
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleDialogClose} color="primary">
                              Cancel
                            </Button>
                            <Button type="submit" color="secondary">
                              Ok
                            </Button>
                          </DialogActions>
                        </form>
                      </Dialog>
                    </div>
                  ) : null}
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
