import React, { useContext } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPassword";
import Register from "./components/Register";
import Home from "./components/Home";
import AdminDashboard from "./components/admin/AdminDashboard";
import Profile from "./components/Profile";
import AdminPendingCOO from "./components/admin/AdminPendingCOO";
import AdminApproveCOO from "./components/admin/AdminApproveCOO";
import AdminPendingReg from "./components/admin/AdminPendingReg";
import AdminApprovedReg from "./components/admin/AdminApprovedReg";
import AdminUserList from "./components/admin/AdminUserList";
import PendingRegForm from "./components/admin/PendingRegForm";
import PendingCOOForm from "./components/admin/PendingCOOForm";
import PendingCOO from "./components/admin/PendingCOOForm";
import Settings from "./components/admin/AdminSettings";
import ViewRegisterForm from "./components/pdf/ViewRegisterForm";
import ViewCooForms from "./components/pdf/ViewCooForms";
import CooForms from "./components/forms/CooForms";
import RegisterForm from "./components/forms/RegisterForm";
import { AuthProvider, AuthContext } from "./Auth";
import PrivateRoute from "./PrivateRoute";
import CommonRoute from "./CommonRoute";
import AdminPrivateRoute from "./AdminPrivateRoute";
import { TermsAndConditions } from "./components/TermsAndConditions";

const app = () => {
  return (
    <AuthProvider>
      <Switch>
        <Route exact path="/Register" component={Register} />
        <Route exact path="/Login" component={Login} />
        <Route exact path="/ForgotPassword" component={ForgotPassword} />
        <Route exact path="/ViewCooForms" component={ViewCooForms} />
        <AdminPrivateRoute
          exact
          path="/AdminDashboard"
          component={AdminDashboard}
        />
        <AdminPrivateRoute exact path="/PendingCOO" component={PendingCOO} />
        <AdminPrivateRoute
          exact
          path="/AdminPendingCOO"
          component={AdminPendingCOO}
        />
        <AdminPrivateRoute
          exact
          path="/AdminApproveCOO"
          component={AdminApproveCOO}
        />
        <AdminPrivateRoute
          exact
          path="/AdminPendingReg"
          component={AdminPendingReg}
        />
        <AdminPrivateRoute
          exact
          path="/AdminApprovedReg"
          component={AdminApprovedReg}
        />
        <AdminPrivateRoute
          exact
          path="/AdminUserList"
          component={AdminUserList}
        />
        <AdminPrivateRoute
          exact
          path="/PendingRegForm"
          permission="approveForms"
          component={PendingRegForm}
        />
        <AdminPrivateRoute
          exact
          path="/PendingCOOForm"
          permission="approveForms"
          component={PendingCOOForm}
        />
        <AdminPrivateRoute exact path="/Settings" component={Settings} />
        <PrivateRoute exact path="/Profile" component={Profile} />
        <PrivateRoute exact path="/RegisterForm" component={RegisterForm} />
        <PrivateRoute exact path="/CooForms" component={CooForms} />
        <CommonRoute
          exact
          path="/viewRegisterFrom"
          component={ViewRegisterForm}
        />
        <CommonRoute exact path="/tnc" component={TermsAndConditions} />
        <LoadIndexRoute />
      </Switch>
    </AuthProvider>
  );
};

const LoadIndexRoute = () => {
  const { userProfile } = useContext(AuthContext);

  if (userProfile?.type == "admin")
    return <AdminPrivateRoute component={AdminDashboard} />;
  else return <PrivateRoute component={Home} />;
};

export default app;
