import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

//production
const app = firebase.default.initializeApp({
  apiKey: "AIzaSyDnPoyI6Fp8zE66fvSYZaqEBzir29UGR60",
  authDomain: "fccisl-backend.firebaseapp.com",
  databaseURL: "https://fccisl-backend.firebaseio.com",
  projectId: "fccisl-backend",
  storageBucket: "fccisl-backend.appspot.com",
  messagingSenderId: "939843117351",
  appId: "1:939843117351:web:bf99129d144981e6f08902",
  measurementId: "G-HSZ7MDBGEK",
});

//testing
// const app = firebase.default.initializeApp({
//   apiKey: "AIzaSyB-CBj0LCwpffKxjk6ciM-FoSuN-NRjlH4",
//   authDomain: "fccisl-testing-env.firebaseapp.com",
//   projectId: "fccisl-testing-env",
//   storageBucket: "fccisl-testing-env.appspot.com",
//   messagingSenderId: "166227924994",
//   appId: "1:166227924994:web:f4afd3a22bc6f6ff976f1b",
//   measurementId: "G-7ZMPMQ67XL",
// });

export default app;
