import React, { useCallback, useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { green, red } from "@material-ui/core/colors";
import { useAlert } from "react-alert";
import Nav from "../Nav.js";
import app from "../../FirebaseInit";
import Loader from "react-loader-spinner";
import { AuthContext } from "../../Auth";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    flexGrow: "2",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
const ColorButton2 = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);

export default function RegisterForm() {
  const classes = useStyles();
  const alert = useAlert();
  const { currentUser } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const {
      CompanyName,
      CompanyRegistrationNo,
      Address,
      ContactPerson,
      Designation,
      Telephone,
      Fax,
      Email,
      ProductType,
      companyseal,
      companyRegFile,
    } = e.target.elements;
    try {
      if (
        !(
          (companyseal.files[0].type == "image/jpeg" ||
            companyseal.files[0].type == "image/png") &&
          (companyRegFile.files[0].type == "image/png" ||
            companyRegFile.files[0].type == "image/jpeg")
        )
      ) {
        alert.error("Please upload a valid image file");
        return;
      }
      setLoading(true);
      var snap = await app
        .firestore()
        .collection("registerForms")
        .doc(currentUser.uid)
        .get();
      if (snap.exists) {
        if (snap.data().status != "rejected") {
          setLoading(false);
          return alert.error("You Already Submitted This Form !");
        }
      }
      var id = 10001;
      var doc = await app
        .firestore()
        .collection("registerForms")
        .orderBy("RegId", "desc")
        .limit(1)
        .get();
      if (!doc.empty) {
        doc.docs.forEach((doc) => {
          id = doc.data().RegId + 1;
        });
      }
      var event = await app
        .storage()
        .ref(`RegisterForm/${currentUser.uid}`)
        .put(companyseal.files[0]);

      var url = await event.ref.getDownloadURL();

      var regEvent = await app
        .storage()
        .ref(`RegisterForm/${currentUser.uid}/reg`)
        .put(companyRegFile.files[0]);

      var regUrl = await regEvent.ref.getDownloadURL();

      await app
        .firestore()
        .collection("registerForms")
        .doc(currentUser.uid)
        .set({
          RegId: id,
          CompanyName: CompanyName.value,
          CompanyRegistrationNo: CompanyRegistrationNo.value,
          Address: Address.value,
          ContactPerson: ContactPerson.value,
          Designation: Designation.value,
          Telephone: Telephone.value,
          Fax: Fax.value ?? "",
          Email: Email.value,
          ProductType: ProductType.value,
          companyseal: url,
          companyRegFile: regUrl,
          status: "pending",
        });

      alert.success("Register From Submitted !");
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e.message);
      alert.error(e.message);
    }
  });

  return (
    <div>
      <Nav />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src={require("../../asserts/ComponentTMP_0-image.jpg")}
            width="10%"
            height="10%"
          />

          <Typography component="h1" variant="h5">
            Federation of Chambers of Commerce and Industry of Sri Lanka
          </Typography>
          <Typography component="h1" variant="h5">
            (FCCISL)
          </Typography>
          <Typography component="h1" variant="h5">
            Certificate of Origin - Registration Form
          </Typography>
          <form className={classes.form} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                Name of the Company
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="CompanyName"
                  name="CompanyName"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Company Registration No
                <TextField
                  required
                  name="CompanyRegistrationNo"
                  variant="outlined"
                  fullWidth
                  id="CompanyRegistrationNo"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Postal Address
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="Address"
                  name="Address"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Contact Person Number
                <TextField
                  required
                  type="number"
                  variant="outlined"
                  fullWidth
                  id="ContactPerson"
                  name="ContactPerson"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                Designation
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="Designation"
                  name="Designation"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* empty space */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <h3>Contact Details :</h3>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* empty space */}
              </Grid>
              <Grid item xs={12} sm={6}>
                Telephone Number
                <TextField
                  required
                  type="number"
                  variant="outlined"
                  fullWidth
                  id="Telephone"
                  name="Telephone"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Fax Number
                <TextField
                  type="number"
                  variant="outlined"
                  fullWidth
                  id="Fax"
                  name="Fax"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Email Address
                <TextField
                  required
                  type="email"
                  variant="outlined"
                  fullWidth
                  id="Email"
                  name="Email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* empty space */}
              </Grid>
              <Grid item md={12} sm={6}>
                <hr />
              </Grid>

              <Grid item xs={12} sm={6}>
                Type of Product being exported
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="ProductType"
                  name="ProductType"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* empty space */}
              </Grid>

              <Grid item md={12} sm={6}>
                <h3>
                  Please register our company at FCCISL enabling us to obtain
                  the Certificate of Origin.
                </h3>
              </Grid>
              <Grid item xs={12} sm={6}>
                Signature of Authorized Officer with Company Seal
              </Grid>
              <Grid item xs={12} sm={6}>
                Copy of company registration
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="file"
                  accept="image/*"
                  id="companyseal"
                  name="companyseal"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="file"
                  accept="image/*"
                  id="companyRegFile"
                  name="companyRegFile"
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                {loading ? (
                  <center style={{ padding: "10px" }}>
                    <Loader
                      type="ThreeDots"
                      color="Black"
                      width="50"
                      height="50"
                    />
                  </center>
                ) : (
                  <ColorButton
                    type="submit"
                    size="large"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Submit
                  </ColorButton>
                )}
              </Grid>
            </Grid>
          </form>
        </div>
        <h2>
          <u>ADDITIONAL INFORMATION</u>
        </h2>
        Payment Categories :<br />
        <ul>
          <li>Chamber Member - LKR 1,000</li>
          <li>Non - Chamber Member-LKR 2,000</li>
        </ul>
        <br />
        <h2>
          <u>
            NOTE : A COPY of the COMPANY REGISTRATION should be given along with
            the above registration form
          </u>
        </h2>
        <br />
        <h3>
          <u>
            Essential Documents To Be Submitted When Obtaining A Certificate Of
            Origin
          </u>
        </h3>
        <br />
        <ul>
          <li>
            A duly filled Certificate of Origin - A 100pg Certificate of Origin
            Book could be purchased from FCCISLat the cost of Rs. 250/=
          </li>
          <li>
            A duly filled “Form B” - An original Form B will be issued by FCCISL
          </li>
          <li>A Customs Declaration Form (CUSDEC) or Commercial Invoice</li>
        </ul>
        <h3>
          <u>NOTE :</u>
        </h3>
        <br />
        As per office policy and record keeping purposes FCCISL will require
        <ul>
          <li>A copy of the duly filled Certificate of Origin</li>
          <li>A copy of the Form B</li>
          <li>
            A copy of the Customs Declaration Form (CUSDEC) or Commercial
            Invoice
          </li>
          From each Certificate of Origin issued
        </ul>
      </Container>
    </div>
  );
}
