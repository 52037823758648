import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { green, red } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    flexGrow: "2",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
const ColorButton2 = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);

export default function CoFormA({
  data,
  setData,
  setCurrentPage,
  disabled = false,
}) {
  const classes = useStyles();

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    setCurrentPage("FormB");
  });

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onFileChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.files,
    });
  };

  return (
    <div>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src={require("../../asserts/ComponentTMP_0-image.jpg")}
            width="10%"
            height="10%"
          />

          <Typography component="h1" variant="h5">
            Federation of Chambers of Commerce and Industry of Sri Lanka
          </Typography>
          <Typography component="h1" variant="h5">
            (FCCISL)
          </Typography>
          <Typography component="h1" variant="h5">
            Certificate of Origin - Form A
          </Typography>
          <form className={classes.form} onSubmit={disabled ? null : onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                Exporter Full Name
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="ExName"
                  name="ExName"
                  value={data.ExName ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Address
                <TextField
                  required
                  name="Address"
                  variant="outlined"
                  fullWidth
                  id="Address"
                  autoFocus
                  value={data.Address ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Exporter’s Registration No
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="ExRegno"
                  name="ExRegno"
                  value={data.ExRegno ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Vessel
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="Vessel"
                  name="Vessel"
                  value={data.Vessel ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Port of Loading
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="PortLoading"
                  name="PortLoading"
                  value={data.PortLoading ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Port of Discharge
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="PortDischarge"
                  name="PortDischarge"
                  value={data.PortDischarge ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Final Destination
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="FinalDestination"
                  name="FinalDestination"
                  value={data.FinalDestination ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Consignee / importer
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="Consignee"
                  name="Consignee"
                  value={data.Consignee ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item md={12} sm={6}>
                Consignee / importer's address
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="ConsigneeAddress"
                  name="ConsigneeAddress"
                  value={data.ConsigneeAddress ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item md={12} sm={6}>
                <hr />
              </Grid>
              <Grid item md={12} sm={6}>
                {/* item table */}
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="center">Container No</TableCell>
                        <TableCell align="center">Packages</TableCell>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow id="addr1">
                        <TableCell component="th" scope="row">
                          1
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ContainerNo1"
                            value={data.ContainerNo1 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{
                              maxlength: 16,
                            }}
                            required
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Packages1"
                            value={data.Packages1 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                            required
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Description1"
                            value={data.Description1 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            required
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="number"
                            name="Quantity1"
                            value={data.Quantity1 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 15 }}
                            required
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow id="addr2">
                        <TableCell component="th" scope="row">
                          2
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ContainerNo2"
                            value={data.ContainerNo2 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            inputProps={{
                              maxlength: 16,
                            }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Packages2"
                            value={data.Packages2 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Description2"
                            value={data.Description2 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="number"
                            name="Quantity2"
                            value={data.Quantity2 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 15 }}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow id="addr3">
                        <TableCell component="th" scope="row">
                          3
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ContainerNo3"
                            value={data.ContainerNo3 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            inputProps={{
                              maxlength: 16,
                            }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Packages3"
                            value={data.Packages3 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Description3"
                            value={data.Description3 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="number"
                            name="Quantity3"
                            value={data.Quantity3 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 15 }}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow id="addr4">
                        <TableCell component="th" scope="row">
                          4
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ContainerNo4"
                            value={data.ContainerNo4 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            inputProps={{
                              maxlength: 16,
                            }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Packages4"
                            value={data.Packages4 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Description4"
                            value={data.Description4 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="number"
                            name="Quantity4"
                            value={data.Quantity4 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 15 }}
                          />
                        </TableCell>
                      </TableRow>

                      <TableRow id="addr5">
                        <TableCell component="th" scope="row">
                          5
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ContainerNo5"
                            value={data.ContainerNo5 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            inputProps={{
                              maxlength: 16,
                            }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Packages5"
                            value={data.Packages5 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Description5"
                            value={data.Description5 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="number"
                            name="Quantity5"
                            value={data.Quantity5 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 15 }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr6">
                        <TableCell component="th" scope="row">
                          6
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ContainerNo6"
                            value={data.ContainerNo6 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            inputProps={{
                              maxlength: 16,
                            }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Packages6"
                            value={data.Packages6 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Description6"
                            value={data.Description6 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="number"
                            name="Quantity6"
                            value={data.Quantity6 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 15 }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr7">
                        <TableCell component="th" scope="row">
                          7
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ContainerNo7"
                            value={data.ContainerNo7 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            inputProps={{
                              maxlength: 16,
                            }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Packages7"
                            value={data.Packages7 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Description7"
                            value={data.Description7 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="number"
                            name="Quantity7"
                            value={data.Quantity7 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 15 }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr8">
                        <TableCell component="th" scope="row">
                          8
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ContainerNo8"
                            value={data.ContainerNo8 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            inputProps={{
                              maxlength: 16,
                            }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Packages8"
                            value={data.Packages8 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Description8"
                            value={data.Description8 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="number"
                            name="Quantity8"
                            value={data.Quantity8 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 15 }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr9">
                        <TableCell component="th" scope="row">
                          9
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ContainerNo9"
                            value={data.ContainerNo9 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            inputProps={{
                              maxlength: 16,
                            }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Packages9"
                            value={data.Packages9 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Description9"
                            value={data.Description9 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="number"
                            name="Quantity9"
                            value={data.Quantity9 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 15 }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr10">
                        <TableCell component="th" scope="row">
                          10
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ContainerNo10"
                            value={data.ContainerNo10 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            inputProps={{
                              maxlength: 16,
                            }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Packages10"
                            value={data.Packages10 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="Description10"
                            value={data.Description10 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 21 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="number"
                            name="Quantity10"
                            value={data.Quantity10 ?? ""}
                            onChange={onChange}
                            disabled={disabled}
                            className="form-control"
                            inputProps={{ maxLength: 15 }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={10} sm={6}>
                <h3>Total Invoice Value (State Currency) </h3>
              </Grid>

              <Grid item xs={10} sm={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  style={{ minWidth: 400 }}
                >
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Currency
                  </InputLabel>
                  <Select
                    native
                    required
                    value={data.Currency ?? ""}
                    onChange={onChange}
                    disabled={disabled}
                    label="Currency"
                    inputProps={{
                      name: "Currency",
                    }}
                  >
                    <option value="LKR">LKR</option>
                    <option value="USD">USD</option>
                    <option value="GBP">GBP</option>
                    <option value="EUR">EUR</option>
                    <option value="SGD">SGD</option>
                    <option value="CAD">CAD</option>
                    <option value="AUD">AUD</option>
                    <option value="INR">INR</option>
                    <option value="RUB">RUB</option>
                    <option value="JPY">JPY</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={7}>
                Total Net Wt.(Kg)
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="TotalNet"
                  name="TotalNet"
                  value={data.TotalNet ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                  type="number"
                  inputProps={{ maxLength: 16 }}
                />
                Total Gross Wt.(Kg)
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="TotalGross"
                  name="TotalGross"
                  value={data.TotalGross ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                  type="number"
                  inputProps={{ maxLength: 16 }}
                />
                Total Cube(M3)
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="TotalCube"
                  name="TotalCube"
                  value={data.TotalCube ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                  type="number"
                  inputProps={{ maxLength: 16 }}
                />
              </Grid>
              <Grid item md={12} sm={6}>
                I the undersigned declare all particulars set forth aboveare
                correctly stated
              </Grid>
              <Grid item xs={12} sm={6}>
                Signature of Exporter / Agent <br />
                {disabled ? (
                  <img
                    src={data.companyseal}
                    style={{ width: "100px", height: "50px" }}
                  />
                ) : (
                  <>
                    {typeof data.companyseal === "string" && (
                      <img
                        src={data.companyseal}
                        style={{ width: "100px", height: "50px" }}
                      />
                    )}
                    <input
                      type="file"
                      accept="image/*"
                      id="companyseal"
                      name="companyseal"
                      onChange={onFileChange}
                      disabled={disabled}
                      required={data.companyseal ? false : true}
                    />
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* empty space */}
              </Grid>
              <Grid item xs={12} sm={6}>
                Date
                <TextField
                  required
                  type="date"
                  variant="outlined"
                  fullWidth
                  id="DateSignature"
                  name="DateSignature"
                  value={data.DateSignature ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Name and designation
                <TextField
                  required
                  variant="outlined"
                  fullWidth
                  id="SignatureName"
                  name="SignatureName"
                  value={data.SignatureName ?? ""}
                  onChange={onChange}
                  disabled={disabled}
                  inputProps={{ maxLength: 48 }}
                />
              </Grid>
              {disabled ? null : (
                <Grid item xs={12} sm={6}>
                  <Link to="/" className={classes.link}>
                    <ColorButton2
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Cancel
                    </ColorButton2>
                  </Link>
                </Grid>
              )}
              {disabled ? null : (
                <Grid item xs={12} sm={6}>
                  <ColorButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Next
                  </ColorButton>
                </Grid>
              )}
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}
