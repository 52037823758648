import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import { positions, Provider } from "react-alert";
import App from "./App";
import AlertTemplate from "react-alert-template-basic";
import "./index.css";
//require('dotenv').config()

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

ReactDOM.render(
  <BrowserRouter>
    <NotificationContainer />
    <Provider template={AlertTemplate} {...options}>
      <App />
      <div className="footer-copyrights">
        <h5>Copyrights © 2022 Sicat Industries</h5>
      </div>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
serviceWorker.unregister();
