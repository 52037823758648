import React, { useEffect, useContext, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Auth";
import Button from "@material-ui/core/Button";
import app from "../../FirebaseInit";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 200,
  },
  B1: {
    color: "orange",
  },
});

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);

export default function MediaCard() {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const [state, setState] = useState(null);

  useEffect(() => {
    app
      .firestore()
      .collection("registerForms")
      .doc(currentUser.uid)
      .get()
      .then((snap) => {
        if (snap.exists) {
          setState(snap.data().status);
        }
      });
  }, []);

  return (
    <Link
      to={
        state == "approved"
          ? "/viewRegisterFrom"
          : state == "pending"
          ? "/"
          : "/RegisterForm"
      }
      style={{ textDecoration: "none" }}
    >
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={require("../../asserts/ID1.png")}
            title="Contemplative Reptile"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {state == "approved"
                ? "View Registraion Form"
                : "Registration Form"}
            </Typography>
            {!!state ? (
              <Typography variant="body2" color="textSecondary" component="p">
                Your application is {state}
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary" component="p">
                You have not submitted this form
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
