import React, { useCallback, useContext, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { grey, orange } from "@material-ui/core/colors";
import Nav from "./Nav";
import { useAlert } from "react-alert";
import app from "../FirebaseInit";
import { Redirect, withRouter } from "react-router";
import { AuthContext } from "../Auth";
import Loader from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",

    flexDirection: "column",
    alignItems: "center",
  },
  pageWrapper: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "black",
  },
  pageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: "1",
  },
  boxWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(9),
  },
  container: {
    width: "200px",
  },
  submit: {
    radius: "20",
    margin: theme.spacing(1, 0, 2),
  },
  button: {
    width: "48%",
    margin: "1px",
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700],
    },
  },
}))(Button);

const ColorButton2 = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
    "&:hover": {
      backgroundColor: grey[700],
    },
  },
}))(Button);

export default function ForgotPassword({ history }) {
  const classes = useStyles();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const userLogin = useCallback(
    async (e) => {
      e.preventDefault();
      const { email } = e.target.elements;
      try {
        setLoading(true);
        await app.auth().sendPasswordResetEmail(email.value.trim());
        alert.success("Password reset link sent !");
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
        alert.error(err.message);
      }
    },
    [history]
  );

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to={"/"} />;
  }

  return (
    <div>
      <Nav />
      <div className={classes.pageWrapper}>
        <Container maxWidth="xs" className={classes.pageContainer}>
          <Paper elevation={3}>
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <AccountCircleOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Forgot Password
              </Typography>
              <form className={classes.boxWrapper} onSubmit={userLogin}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                {loading ? (
                  <center style={{ padding: "10px" }}>
                    <Loader
                      type="ThreeDots"
                      color="Black"
                      width="50"
                      height="50"
                    />
                  </center>
                ) : (
                  <ColorButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    color=""
                    className={classes.submit}
                  >
                    Send Reset Link
                  </ColorButton>
                )}
                <div className={classes.container}>
                  <Link to="/register" style={{ textDecoration: "none" }}>
                    <ColorButton2
                      type="button"
                      fullWidth
                      variant="contained"
                      color=""
                      className={classes.button}
                    >
                      SIGN UP
                    </ColorButton2>
                  </Link>
                  <Link to="/login" style={{ textDecoration: "none" }}>
                    <ColorButton2
                      type="button"
                      fullWidth
                      variant="contained"
                      color=""
                      className={classes.button}
                    >
                      Login
                    </ColorButton2>
                  </Link>
                </div>
              </form>
            </div>
          </Paper>
        </Container>
      </div>
    </div>
  );
}
