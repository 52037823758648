import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import app from "./FirebaseInit";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [pending, setPending] = useState(true);
  const [prices, setPrices] = useState(null);

  useEffect(() => {
    app.auth().onAuthStateChanged(async (user) => {
      if (!user) setCurrentUser(null);
      try {
        var snap = await app
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get();
        if (snap.exists) {
          var data = snap.data();
          data.id = user.uid;
          setCurrentUser(user);
          setUserProfile(data);
        }
      } catch (err) {
        console.log(err.message);
        app.auth().signOut();
      }
      setPending(false);
    });
    app
      .firestore()
      .collection("settings")
      .doc("prices")
      .get()
      .then((data) => {
        setPrices(data.data());
      });
  }, []);

  if (pending)
    return (
      <center style={{ padding: "10px", height: "100vh" }}>
        <Loader type="ThreeDots" color="Black" width="50" height="50" />
      </center>
    );

  return (
    <AuthContext.Provider value={{ currentUser, userProfile, prices }}>
      {children}
    </AuthContext.Provider>
  );
};
