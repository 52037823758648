import React from "react";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PersonIcon from "@material-ui/icons/Person";
import BarChartIcon from "@material-ui/icons/BarChart";
import app from "../../FirebaseInit";
import AssignmentIcon from "@material-ui/icons/Assignment";

export const AdminNav = (
  <div>
    <Link to="/Home" style={{ textDecoration: "none", color: "orange" }}>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    </Link>
    <Link
      to="/AdminPendingCOO"
      style={{ textDecoration: "none", color: "orange" }}
    >
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Pending COO Forms" />
      </ListItem>
    </Link>
    
    <Link
      to="/AdminPendingReg"
      style={{ textDecoration: "none", color: "orange" }}
    >
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Pending Registration" />
      </ListItem>
    </Link>
    
    <Link
      to="/AdminApproveCOO"
      style={{ textDecoration: "none", color: "orange" }}
    >
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Approved COO Forms" />
      </ListItem>
    </Link>
    <Link
      to="/AdminApprovedReg"
      style={{ textDecoration: "none", color: "orange" }}
    >
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Approved Registeration" />
      </ListItem>
    </Link>
    <Link
      to="/AdminUserList"
      style={{ textDecoration: "none", color: "orange" }}
    >
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="User List" />
      </ListItem>
    </Link>
    <Link
      to="/Settings"
      style={{ textDecoration: "none", color: "orange" }}
    >
      <ListItem button>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Setting" />
      </ListItem>
    </Link>
    <ListItem
      button
      onClick={(e) => {
        e.preventDefault();
        app.auth().signOut();
      }}
    >
      <ListItemIcon>
        <PersonIcon />
      </ListItemIcon>
      <ListItemText primary="Logout" />
    </ListItem>
  </div>
);

export const secondaryListItems = (
  <div>
    <ListSubheader inset>Saved reports</ListSubheader>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Current month" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Last quarter" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Year-end sale" />
    </ListItem>
  </div>
);
