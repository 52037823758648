import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import COFormA from "./COFormA";
import COFormB from "./COFormB";
import { makeStyles } from "@material-ui/core/styles";
import app from "../../FirebaseInit";
//import QRCode from "qrcode.react";

function useQuery() {
  return new URLSearchParams(window.location.search);
}

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 200,
  },
  B1: {
    color: "orange",
  },
});

export default function ViewCooForms() {
  const [data, setData] = useState(null);
  let query = useQuery();
  var id = query.get("id");
  let history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    if (id === null) return history.push("/");
    app
      .firestore()
      .collection("COOForms")
      .doc(id)
      .get()
      .then((snap) => {
        if (!snap.exists) return history.push("/");
        if (snap.data().status != "approved") history.push("/");
        app
          .firestore()
          .collection("COOForms")
          .doc(id)
          .get()
          .then((snap) => {
            setData(snap.data());
          });
      });
  }, []);

  if (data == null)
    return (
      <center>
        <Loader type="ThreeDots" color="Black" width="50" height="50" />
      </center>
    );

  return (
    <div>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "20%",
         
          height: "1123.2px",
        }}
      >
        <COFormA data={data} />
      </div>
      <div
        style={{
          
          position: "absolute",
          left: "50%",
          top: "103%",
         
          height: "1123.2px",
        }}
      >
        <COFormB data={data} />
      </div>
    </div>
  );
}
