import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { green, red } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Loader from "react-loader-spinner";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { AuthContext } from "../../Auth";
import app from "../../FirebaseInit";
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import Checkbox from "@material-ui/core/Checkbox";
import * as firebase from "firebase/app";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    flexGrow: "2",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
const ColorButton2 = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default function CoFormB({
  data,
  formAData,
  setData,
  setCurrentPage,
  currentPage,
  disabled = false,
  docId,
}) {
  const [tna, setTna] = useState(false);
  const classes = useStyles();
  const { currentUser, userProfile } = useContext(AuthContext);
  const alert = useAlert();
  let history = useHistory();
  let query = useQuery();

  const onSubmit = async (e) => {
    e.preventDefault();
    setData(data);
    const {
      ConsigneeAddress,
      Consignee,
      ExName,
      Address,
      ExRegno,
      Vessel,
      PortLoading,
      PortDischarge,
      FinalDestination,
      ContainerNo1,
      ContainerNo2,
      ContainerNo3,
      ContainerNo4,
      ContainerNo5,
      ContainerNo6,
      ContainerNo7,
      ContainerNo8,
      ContainerNo9,
      ContainerNo10,
      Packages1,
      Packages2,
      Packages3,
      Packages4,
      Packages5,
      Packages6,
      Packages7,
      Packages8,
      Packages9,
      Packages10,
      Description1,
      Description2,
      Description3,
      Description4,
      Description5,
      Description6,
      Description7,
      Description8,
      Description9,
      Description10,
      Quantity1,
      Quantity2,
      Quantity3,
      Quantity4,
      Quantity5,
      Quantity6,
      Quantity7,
      Quantity8,
      Quantity9,
      Quantity10,
      TotalNet,
      TotalCube,
      TotalGross,
      companyseal,
      DateSignature,
      SignatureName,
    } = formAData;
    const {
      Marks1,
      Marks2,
      Marks3,
      Marks4,
      Marks5,
      Marks6,
      Marks7,
      Marks8,
      Marks9,
      Marks10,
      SailingSate1,
      SailingSate2,
      SailingSate3,
      SailingSate4,
      SailingSate5,
      SailingSate6,
      SailingSate7,
      SailingSate8,
      SailingSate9,
      SailingSate10,
      Destination1,
      Destination2,
      Destination3,
      Destination4,
      Destination5,
      Destination6,
      Destination7,
      Destination8,
      Destination9,
      Destination10,
      CompanyName,
      OfficerName,
      documents,
    } = data;
    if (typeof companyseal != "string")
      if (
        !(
          companyseal[0].type == "image/jpeg" ||
          companyseal[0].type == "image/png"
        )
      ) {
        alert.error("Please upload a valid image file");
        return;
      }

    try {
      setCurrentPage("Submit");
      var id = 10001;
      var doc = await app
        .firestore()
        .collection("COOForms")
        .orderBy("RefNo", "desc")
        .limit(1)
        .get();
      if (!doc.empty) {
        doc.docs.forEach((doc) => {
          id = doc.data().RefNo + 1;
        });
      }
      if (docId) {
        var doc = app.firestore().collection("COOForms").doc(docId);
        await doc.update({
          userId: currentUser.uid,
          Consignee,
          ConsigneeAddress,
          ExName,
          Address,
          ExRegno,
          Vessel,
          PortLoading,
          PortDischarge,
          FinalDestination,
          ContainerNo1: ContainerNo1 ?? "",
          ContainerNo2: ContainerNo2 ?? "",
          ContainerNo3: ContainerNo3 ?? "",
          ContainerNo4: ContainerNo4 ?? "",
          ContainerNo5: ContainerNo5 ?? "",
          ContainerNo6: ContainerNo6 ?? "",
          ContainerNo7: ContainerNo7 ?? "",
          ContainerNo8: ContainerNo8 ?? "",
          ContainerNo9: ContainerNo9 ?? "",
          ContainerNo10: ContainerNo10 ?? "",
          Packages1: Packages1 ?? "",
          Packages2: Packages2 ?? "",
          Packages3: Packages3 ?? "",
          Packages4: Packages4 ?? "",
          Packages5: Packages5 ?? "",
          Packages6: Packages6 ?? "",
          Packages7: Packages7 ?? "",
          Packages8: Packages8 ?? "",
          Packages9: Packages9 ?? "",
          Packages10: Packages10 ?? "",
          Description1: Description1 ?? "",
          Description2: Description2 ?? "",
          Description3: Description3 ?? "",
          Description4: Description4 ?? "",
          Description5: Description5 ?? "",
          Description6: Description6 ?? "",
          Description7: Description7 ?? "",
          Description8: Description8 ?? "",
          Description9: Description9 ?? "",
          Description10: Description10 ?? "",
          Quantity1: Quantity1 ?? "",
          Quantity2: Quantity2 ?? "",
          Quantity3: Quantity3 ?? "",
          Quantity4: Quantity4 ?? "",
          Quantity5: Quantity5 ?? "",
          Quantity6: Quantity6 ?? "",
          Quantity7: Quantity7 ?? "",
          Quantity8: Quantity8 ?? "",
          Quantity9: Quantity9 ?? "",
          Quantity10: Quantity10 ?? "",
          TotalNet,
          TotalCube,
          TotalGross,
          DateSignature,
          SignatureName,
          Marks1: Marks1 ?? "",
          Marks2: Marks2 ?? "",
          Marks3: Marks3 ?? "",
          Marks4: Marks4 ?? "",
          Marks5: Marks5 ?? "",
          Marks6: Marks6 ?? "",
          Marks7: Marks7 ?? "",
          Marks8: Marks8 ?? "",
          Marks9: Marks9 ?? "",
          Marks10: Marks10 ?? "",
          SailingSate1: SailingSate1 ?? "",
          SailingSate2: SailingSate2 ?? "",
          SailingSate3: SailingSate3 ?? "",
          SailingSate4: SailingSate4 ?? "",
          SailingSate5: SailingSate5 ?? "",
          SailingSate6: SailingSate6 ?? "",
          SailingSate7: SailingSate7 ?? "",
          SailingSate8: SailingSate8 ?? "",
          SailingSate9: SailingSate9 ?? "",
          SailingSate10: SailingSate10 ?? "",
          Destination1: Destination1 ?? "",
          Destination2: Destination2 ?? "",
          Destination3: Destination3 ?? "",
          Destination4: Destination4 ?? "",
          Destination5: Destination5 ?? "",
          Destination6: Destination6 ?? "",
          Destination7: Destination7 ?? "",
          Destination8: Destination8 ?? "",
          Destination9: Destination9 ?? "",
          Destination10: Destination10 ?? "",
          CompanyName,
          OfficerName,
          status: "pending",
        });
        if (typeof companyseal != "string") {
          var event = await app
            .storage()
            .ref(`COOForms/${snap.id}`)
            .put(companyseal[0]);

          var url = await event.ref.getDownloadURL();
          await snap.update({ companyseal: url });
        }
        alert.success("Register From Submitted !");
        return history.push("/");
      }
      var snap = await app
        .firestore()
        .collection("COOForms")
        .add({
          userId: currentUser.uid,
          Consignee,
          ConsigneeAddress,
          ExName,
          Address,
          ExRegno,
          RefNo: id,
          Vessel,
          PortLoading,
          PortDischarge,
          FinalDestination,
          ContainerNo1: ContainerNo1 ?? "",
          ContainerNo2: ContainerNo2 ?? "",
          ContainerNo3: ContainerNo3 ?? "",
          ContainerNo4: ContainerNo4 ?? "",
          ContainerNo5: ContainerNo5 ?? "",
          ContainerNo6: ContainerNo6 ?? "",
          ContainerNo7: ContainerNo7 ?? "",
          ContainerNo8: ContainerNo8 ?? "",
          ContainerNo9: ContainerNo9 ?? "",
          ContainerNo10: ContainerNo10 ?? "",
          Packages1: Packages1 ?? "",
          Packages2: Packages2 ?? "",
          Packages3: Packages3 ?? "",
          Packages4: Packages4 ?? "",
          Packages5: Packages5 ?? "",
          Packages6: Packages6 ?? "",
          Packages7: Packages7 ?? "",
          Packages8: Packages8 ?? "",
          Packages9: Packages9 ?? "",
          Packages10: Packages10 ?? "",
          Description1: Description1 ?? "",
          Description2: Description2 ?? "",
          Description3: Description3 ?? "",
          Description4: Description4 ?? "",
          Description5: Description5 ?? "",
          Description6: Description6 ?? "",
          Description7: Description7 ?? "",
          Description8: Description8 ?? "",
          Description9: Description9 ?? "",
          Description10: Description10 ?? "",
          Quantity1: Quantity1 ?? "",
          Quantity2: Quantity2 ?? "",
          Quantity3: Quantity3 ?? "",
          Quantity4: Quantity4 ?? "",
          Quantity5: Quantity5 ?? "",
          Quantity6: Quantity6 ?? "",
          Quantity7: Quantity7 ?? "",
          Quantity8: Quantity8 ?? "",
          Quantity9: Quantity9 ?? "",
          Quantity10: Quantity10 ?? "",
          TotalNet,
          TotalCube,
          TotalGross,
          DateSignature,
          SignatureName,
          Marks1: Marks1 ?? "",
          Marks2: Marks2 ?? "",
          Marks3: Marks3 ?? "",
          Marks4: Marks4 ?? "",
          Marks5: Marks5 ?? "",
          Marks6: Marks6 ?? "",
          Marks7: Marks7 ?? "",
          Marks8: Marks8 ?? "",
          Marks9: Marks9 ?? "",
          Marks10: Marks10 ?? "",
          SailingSate1: SailingSate1 ?? "",
          SailingSate2: SailingSate2 ?? "",
          SailingSate3: SailingSate3 ?? "",
          SailingSate4: SailingSate4 ?? "",
          SailingSate5: SailingSate5 ?? "",
          SailingSate6: SailingSate6 ?? "",
          SailingSate7: SailingSate7 ?? "",
          SailingSate8: SailingSate8 ?? "",
          SailingSate9: SailingSate9 ?? "",
          SailingSate10: SailingSate10 ?? "",
          Destination1: Destination1 ?? "",
          Destination2: Destination2 ?? "",
          Destination3: Destination3 ?? "",
          Destination4: Destination4 ?? "",
          Destination5: Destination5 ?? "",
          Destination6: Destination6 ?? "",
          Destination7: Destination7 ?? "",
          Destination8: Destination8 ?? "",
          Destination9: Destination9 ?? "",
          Destination10: Destination10 ?? "",
          CompanyName,
          OfficerName,
          status: "pending",
        });
      var event = await app
        .storage()
        .ref(`COOForms/${snap.id}`)
        .put(companyseal[0]);

      var url = await event.ref.getDownloadURL();
      await snap.update({ companyseal: url });

      if (documents) {
        var docArray = [];

        for (var i = 0; i < documents.length; i++) {
          var event = await app
            .storage()
            .ref(`COOForms/${snap.id}/documents/${i}`)
            .put(documents[i]);
          var url = await event.ref.getDownloadURL();
          docArray.push(url);
        }

        await snap.update({ documents: docArray });
      }

      query.set("id", snap.id);
      await snap.update({
        SubmittedDate: firebase.default.firestore.FieldValue.serverTimestamp(),
      });
      if (userProfile.monthly ?? false) {
        alert.success("Register From Submitted !");
        return history.push("/");
      }
      snap.update({ status: "paymentPending" });
      alert.info("Please while we redirect you to the payment page !");
      var getPaymentSession = app
        .functions()
        .httpsCallable("getPaymentSession");
      var res = await getPaymentSession({ id: snap.id });
      snap.update({ successIndicator: res.data.successIndicator });
      window.Checkout.configure({
        session: {
          id: res.data["session.id"],
        },
        order: {
          description: "COO Form Fee",
        },
        interaction: {
          merchant: {
            name: "FCCISL",
          },
          displayControl: {
            // you may change these settings as you prefer
            billingAddress: "HIDE",
            customerEmail: "HIDE",
            orderSummary: "SHOW",
            shipping: "HIDE",
          },
        },
      });
      return window.Checkout.showPaymentPage();
    } catch (e) {
      snap.delete();
      setCurrentPage("FormB");
      console.error(e.message);
      alert.error(e.message);
    }
  };

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onFileChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.files,
    });
  };

  return (
    <div>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography align="center" component="h1" variant="h5">
            <u>Form B</u>
          </Typography>
          <img
            src={require("../../asserts/ComponentTMP_0-image.jpg")}
            width="20%"
            height="20%"
          />

          <Typography align="left">
            Secretary General
            <br />
            Federation of Chambers of Commerce & Industry of Sri Lanka <br />
            "Federation House" <br />
            # 696, 3/4, Havelok Road, Colombo 06, Sri Lanka.
            <br />
            <br />
            <br />
            <br />
            Dear Sir, <br />
            <br />
            We enclose a copy of the Certificate of Origin and Invoice relating
            to the shipment details as set out below.
          </Typography>

          <form className={classes.form} onSubmit={disabled ? null : onSubmit}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={6}>
                {/* item table */}
                <TableContainer>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell align="center">Item Exported</TableCell>
                        <TableCell align="center">Quantity</TableCell>
                        <TableCell align="center">Marks & Nos</TableCell>
                        <TableCell align="center">
                          Vessel & & Sailing Sate
                        </TableCell>
                        <TableCell align="center">Destination</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow id="addr1">
                        <TableCell component="th" scope="row">
                          1
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="ItemExported1"
                            value={formAData.ContainerNo1}
                            onChange={onChange}
                            className="form-control"
                            required
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="Quantity1"
                            value={formAData.Quantity1}
                            className="form-control"
                            onChange={onChange}
                            required
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo1 == null ||
                                  formAData.ContainerNo1 == ""
                            }
                            name="Marks1"
                            value={data.Marks1}
                            onChange={onChange}
                            className="form-control"
                            inputProps={{ maxLength: 16 }}
                            required
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo1 == null ||
                                  formAData.ContainerNo1 == ""
                            }
                            name="SailingSate1"
                            value={data.SailingSate1}
                            onChange={onChange}
                            className="form-control"
                            required
                            inputProps={{ maxLength: 12 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo1 == null ||
                                  formAData.ContainerNo1 == ""
                            }
                            name="Destination1"
                            value={data.Destination1}
                            onChange={onChange}
                            inputProps={{ maxLength: 16 }}
                            className="form-control"
                            required
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr2">
                        <TableCell component="th" scope="row">
                          2
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="ItemExported2"
                            value={formAData.ContainerNo2}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="Quantity2"
                            value={formAData.Quantity2}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo2 == null ||
                                  formAData.ContainerNo2 == ""
                            }
                            name="Marks2"
                            value={data.Marks2}
                            onChange={onChange}
                            inputProps={{ maxLength: 16 }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo2 == null ||
                                  formAData.ContainerNo2 == ""
                            }
                            name="SailingSate2"
                            value={data.SailingSate2}
                            onChange={onChange}
                            className="form-control"
                            inputProps={{ maxLength: 12 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo2 == null ||
                                  formAData.ContainerNo2 == ""
                            }
                            name="Destination2"
                            value={data.Destination2}
                            inputProps={{ maxLength: 16 }}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr3">
                        <TableCell component="th" scope="row">
                          3
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="ItemExported3"
                            value={formAData.ContainerNo3}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="Quantity3"
                            value={formAData.Quantity3}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo3 == null ||
                                  formAData.ContainerNo3 == ""
                            }
                            name="Marks3"
                            value={data.Marks3}
                            onChange={onChange}
                            inputProps={{ maxLength: 16 }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo3 == null ||
                                  formAData.ContainerNo3 == ""
                            }
                            name="SailingSate3"
                            value={data.SailingSate3}
                            onChange={onChange}
                            className="form-control"
                            inputProps={{ maxLength: 12 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo3 == null ||
                                  formAData.ContainerNo3 == ""
                            }
                            name="Destination3"
                            value={data.Destination3}
                            inputProps={{ maxLength: 16 }}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr4">
                        <TableCell component="th" scope="row">
                          4
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="ItemExported4"
                            value={formAData.ContainerNo4}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="Quantity4"
                            value={formAData.Quantity4}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo4 == null ||
                                  formAData.ContainerNo4 == ""
                            }
                            name="Marks4"
                            value={data.Marks4}
                            onChange={onChange}
                            inputProps={{ maxLength: 16 }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo4 == null ||
                                  formAData.ContainerNo4 == ""
                            }
                            name="SailingSate4"
                            value={data.SailingSate4}
                            className="form-control"
                            onChange={onChange}
                            inputProps={{ maxLength: 12 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo4 == null ||
                                  formAData.ContainerNo4 == ""
                            }
                            name="Destination4"
                            value={data.Destination4}
                            inputProps={{ maxLength: 16 }}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr5">
                        <TableCell component="th" scope="row">
                          5
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="ItemExported5"
                            value={formAData.ContainerNo5}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="Quantity5"
                            value={formAData.Quantity5}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo5 == null ||
                                  formAData.ContainerNo5 == ""
                            }
                            onChange={onChange}
                            name="Marks5"
                            value={data.Marks5}
                            inputProps={{ maxLength: 16 }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo5 == null ||
                                  formAData.ContainerNo5 == ""
                            }
                            name="SailingSate5"
                            value={data.SailingSate5}
                            onChange={onChange}
                            className="form-control"
                            inputProps={{ maxLength: 12 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo5 == null ||
                                  formAData.ContainerNo5 == ""
                            }
                            name="Destination5"
                            value={data.Destination5}
                            inputProps={{ maxLength: 16 }}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr6">
                        <TableCell component="th" scope="row">
                          6
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="ItemExported6"
                            value={formAData.ContainerNo6}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="Quantity6"
                            value={formAData.Quantity6}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo6 == null ||
                                  formAData.ContainerNo6 == ""
                            }
                            name="Marks6"
                            value={data.Marks6}
                            onChange={onChange}
                            inputProps={{ maxLength: 16 }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo6 == null ||
                                  formAData.ContainerNo6 == ""
                            }
                            name="SailingSate6"
                            value={data.SailingSate6}
                            onChange={onChange}
                            className="form-control"
                            inputProps={{ maxLength: 12 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo6 == null ||
                                  formAData.ContainerNo6 == ""
                            }
                            name="Destination6"
                            value={data.Destination6}
                            inputProps={{ maxLength: 16 }}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr7">
                        <TableCell component="th" scope="row">
                          7
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="ItemExported7"
                            value={formAData.ContainerNo7}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="Quantity7"
                            value={formAData.Quantity7}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo7 == null ||
                                  formAData.ContainerNo7 == ""
                            }
                            name="Marks7"
                            value={data.Marks7}
                            onChange={onChange}
                            inputProps={{ maxLength: 16 }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo7 == null ||
                                  formAData.ContainerNo7 == ""
                            }
                            name="SailingSate7"
                            value={data.SailingSate7}
                            onChange={onChange}
                            className="form-control"
                            inputProps={{ maxLength: 12 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo7 == null ||
                                  formAData.ContainerNo7 == ""
                            }
                            name="Destination7"
                            value={data.Destination7}
                            inputProps={{ maxLength: 16 }}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr8">
                        <TableCell component="th" scope="row">
                          8
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ItemExported8"
                            value={formAData.ContainerNo8}
                            onChange={onChange}
                            className="form-control"
                            disabled
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="Quantity8"
                            value={formAData.Quantity8}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo8 == null ||
                                  formAData.ContainerNo8 == ""
                            }
                            name="Marks8"
                            value={data.Marks8}
                            onChange={onChange}
                            inputProps={{ maxLength: 16 }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo8 == null ||
                                  formAData.ContainerNo8 == ""
                            }
                            name="SailingSate8"
                            value={data.SailingSate8}
                            onChange={onChange}
                            className="form-control"
                            inputProps={{ maxLength: 12 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo8 == null ||
                                  formAData.ContainerNo8 == ""
                            }
                            name="Destination8"
                            value={data.Destination8}
                            inputProps={{ maxLength: 16 }}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr9">
                        <TableCell component="th" scope="row">
                          9
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ItemExported9"
                            value={formAData.ContainerNo9}
                            onChange={onChange}
                            className="form-control"
                            disabled
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="Quantity9"
                            value={formAData.Quantity9}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo9 == null ||
                                  formAData.ContainerNo9 == ""
                            }
                            name="Marks9"
                            value={data.Marks9}
                            onChange={onChange}
                            inputProps={{ maxLength: 16 }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo9 == null ||
                                  formAData.ContainerNo9 == ""
                            }
                            name="SailingSate9"
                            value={data.SailingSate9}
                            onChange={onChange}
                            className="form-control"
                            inputProps={{ maxLength: 12 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo9 == null ||
                                  formAData.ContainerNo9 == ""
                            }
                            name="Destination9"
                            value={data.Destination9}
                            inputProps={{ maxLength: 16 }}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow id="addr10">
                        <TableCell component="th" scope="row">
                          10
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            name="ItemExported10"
                            value={formAData.ContainerNo10}
                            onChange={onChange}
                            className="form-control"
                            disabled
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled
                            name="Quantity10"
                            value={formAData.Quantity10}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo10 == null ||
                                  formAData.ContainerNo10 == ""
                            }
                            name="Marks10"
                            value={data.Marks10}
                            onChange={onChange}
                            inputProps={{ maxLength: 16 }}
                            className="form-control"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo10 == null ||
                                  formAData.ContainerNo10 == ""
                            }
                            name="SailingSate10"
                            value={data.SailingSate10}
                            onChange={onChange}
                            className="form-control"
                            inputProps={{ maxLength: 12 }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <TextField
                            type="text"
                            disabled={
                              disabled
                                ? true
                                : formAData.ContainerNo10 == null ||
                                  formAData.ContainerNo10 == ""
                            }
                            name="Destination10"
                            value={data.Destination10}
                            inputProps={{ maxLength: 16 }}
                            onChange={onChange}
                            className="form-control"
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={12}>
                Upload Documents (Customs Declaration Form (CUSDEC) or
                Commercial Invoice)
                <br />
                {disabled ? (
                  data.documents &&
                  data.documents.map((item, index) => {
                    return (
                      <div key={index}>
                        <a
                          href={item}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                         {index+1}. View Document
                        </a>
                      </div>
                    );
                  })
                ) : (
                  <input
                    type="file"
                    id="EsDocs"
                    name="documents"
                    multiple="multiple"
                    disabled={disabled}
                    onChange={onFileChange}
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                Company
                <TextField
                  disabled={disabled}
                  required
                  type="text"
                  variant="outlined"
                  fullWidth
                  id="CompanyName"
                  name="CompanyName"
                  value={data.CompanyName}
                  inputProps={{ maxLength: 16 }}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Name & Designation of Officer
                <TextField
                  disabled={disabled}
                  required
                  variant="outlined"
                  fullWidth
                  id="OfficerName"
                  name="OfficerName"
                  value={data.OfficerName}
                  onChange={onChange}
                />
              </Grid>
              {disabled ? null : (
                <>
                  <Grid item xs={12} sm={12}>
                    <Checkbox
                      color="primary"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                      value={tna}
                      onChange={() => {
                        setTna(!tna);
                      }}
                    />
                    accept{" "}
                    <Link to="/tnc" target="_blank">
                      terms and conditions
                    </Link>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <img
                      src={require("../../asserts/visa.jpeg")}
                      width="200px"
                    />
                  </Grid>
                </>
              )}
              {disabled ? null : (
                <Grid item xs={12} sm={6}>
                  {currentPage == "Submit" ? (
                    <center style={{ padding: "10px" }}>
                      <Loader
                        type="ThreeDots"
                        color="Black"
                        width="50"
                        height="50"
                      />
                    </center>
                  ) : (
                    <ColorButton2
                      type="button"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      onClick={() => setCurrentPage("FormA")}
                    >
                      Back
                    </ColorButton2>
                  )}
                </Grid>
              )}
              {disabled ? null : (
                <Grid item xs={12} sm={6}>
                  {currentPage == "Submit" ? (
                    <center style={{ padding: "10px" }}>
                      <Loader
                        type="ThreeDots"
                        color="Black"
                        width="50"
                        height="50"
                      />
                    </center>
                  ) : (
                    <ColorButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={!tna}
                      className={classes.submit}
                    >
                      Submit
                    </ColorButton>
                  )}
                </Grid>
              )}
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}
