import React, { useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Nav from "./Nav";
import RegCard from "./homecards/RegCard";
import OriginCard from "./homecards/OriginCard";
import CooTable from "./CooTable";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import app from "../FirebaseInit";
import { AuthContext } from "../Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(10),
    paddingLeft: "5em",
    paddingRight: "5em",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default function Home() {
  const classes = useStyles();
  let history = useHistory();
  let query = useQuery();
  var resultIndicator = query.get("resultIndicator");
  const { currentUser } = useContext(AuthContext);
  const alert = useAlert();

  const paymentFailed = () =>
    alert.error("Payment failed !", { timeout: 60000 });

  const verifyPayment = async () => {
    if (resultIndicator) {
      var id = query.get("id");
      if (!id) {
        paymentFailed();
        return history.push("/");
      }

      alert.info("Payment beign verified !");
      var snap = app.firestore().collection("COOForms").doc(id);
      var doc = await snap.get();
      if (!doc.exists) {
        paymentFailed();
        return history.push("/");
      }
      var data = doc.data();
      if (data.userId != currentUser.uid) {
        alert.error("You are not authorized to perform this action");
        paymentFailed();
        return history.push("/");
      }
      if (data.status != "paymentPending") {
        alert.info("Payment already verified !");
        return history.push("/");
      }
      if (data.successIndicator === resultIndicator) {
        await snap.update({ status: "pending" });
        alert.success("Payment successful ! page will refresh in 5 seconds", {
          timeout: 60000,
        });
        setTimeout(function () {
          window.location.reload(1);
        }, 5000);
        return history.push("/");
      }

      paymentFailed();
      return history.push("/");
    }
  };

  useEffect(() => {
    verifyPayment();
  }, []);

  function FormRow() {
    return (
      <center>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <RegCard />
          </Grid>
          <Grid item xs={6}>
            <OriginCard />
          </Grid>
        </Grid>
      </center>
    );
  }

  return (
    <div className={classes.root}>
      <Nav />
      <div className={classes.paper}>
        <FormRow />
        <CooTable />
      </div>
    </div>
  );
}
