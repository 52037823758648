import React, { useEffect, useState, useCallback, useContext } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Loader from "react-loader-spinner";
import Title from "../Title";
import app from "../../../FirebaseInit";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useAlert } from "react-alert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { AuthContext } from "../../../Auth";

const CustomMenu = ({
  params,
  promoteUser,
  handleEnableUser,
  handleDisableUser,
  userProfile,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {userProfile.promoteUser ? (
          <MenuItem
            onClick={() => {
              promoteUser(params.value.id, params.value.monthly);
              handleMenuClose();
            }}
          >
            Promote as {params.value.monthly ? "Regualr" : "Monthly"} User
          </MenuItem>
        ) : null}
        {userProfile.disableUser ? (
          <MenuItem
            onClick={() => {
              params.value.disabled
                ? handleEnableUser(params.value.id)
                : handleDisableUser(params.value.id);
              handleMenuClose();
            }}
          >
            {params.value.disabled ? "Enable" : "Disable"} User
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};

export default function UserList({ userType = "user" }) {
  const [rows, setRows] = useState(null);
  const { currentUser, userProfile } = useContext(AuthContext);
  const alert = useAlert();
  const promoteUser = async (uid, isMonthly) => {
    try {
      isMonthly
        ? alert.info("Promoting to Regular User...")
        : alert.info("Promoting to Monthly User...");
      await app
        .firestore()
        .collection("users")
        .doc(uid)
        .update({ monthly: !isMonthly });
      alert.success("User Promoted");
      return window.location.reload();
    } catch (e) {
      console.log(e);
      alert.error("Error Occured !");
    }
  };

  const deleteAdmin = async (uid) => {
    try {
      alert.info("Deleting Admin...");
      const deleteUser = app.functions().httpsCallable("deleteUser");
      const result = await deleteUser({ uid: uid });
      if (result.data.code == 200) {
        alert.success("Admin Deleted !");
        return window.location.reload();
      }
      alert.error("Admin deletion failed !");
    } catch (err) {
      console.log(err);
      alert.error("Admin deletion failed !");
    }
  };

  const handleDisableUser = async (uid) => {
    try {
      alert.info("Disabling User...");
      const disableUser = app.functions().httpsCallable("disableUser");
      const result = await disableUser({ uid: uid });
      if (result.data.code == 200) {
        alert.success("User Disabled !");
        return window.location.reload();
      }
      alert.error("User disable failed !");
    } catch (err) {
      console.log(err);
      alert.error("User disable failed !");
    }
  };

  const handleEnableUser = async (uid) => {
    try {
      alert.info("Enabling User...");
      const enableUser = app.functions().httpsCallable("enableUser");
      const result = await enableUser({ uid: uid });
      if (result.data.code == 200) {
        alert.success("User Enabled !");
        return window.location.reload();
      }
      alert.error("User enable failed !");
    } catch (err) {
      console.log(err);
      alert.error("User enable failed !");
    }
  };

  let columns;
  if (userType == "user")
    columns = [
      {
        field: "id",
        headerName: "User Id",
        width: 200,
      },
      { field: "monthly", headerName: "Is Monthly", width: 150 },
      { field: "email", headerName: "Email", width: 200 },
      {
        field: "companyName",
        headerName: "Company Name",
        width: 200,
      },
      {
        field: "coos",
        headerName: "Number of COO",
        width: 150,
        type: "number",
      },
      {
        field: "disabled",
        headerName: "Is Disabled",
        width: 150,
        type: "number",
      },
      {
        field: "additionalData",
        headerName: "Actions",
        width: 150,
        sortable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => (
          <CustomMenu
            params={params}
            handleDisableUser={handleDisableUser}
            handleEnableUser={handleEnableUser}
            promoteUser={promoteUser}
            userProfile={userProfile}
          />
        ),
      },
    ];
  else
    columns = [
      {
        field: "id",
        headerName: "User Id",
        width: 200,
      },
      { field: "email", headerName: "Email", width: 200 },
      {
        field: "addRemoveAdmins",
        headerName: "Add / Remove Admins",
        width: 180,
      },
      {
        field: "approveForms",
        headerName: "Approve Forms",
        width: 150,
      },
      {
        field: "changeCOOPrice",
        headerName: "Change COO Price",
        width: 160,
      },
      {
        field: "disableUser",
        headerName: "Disable User",
        width: 130,
      },
      {
        field: "promoteUser",
        headerName: "Promote User",
        width: 130,
      },
      {
        field: "viewPayments",
        headerName: "View Payments",
        width: 130,
      },
      {
        field: "additionalData",
        headerName: "Actions",
        width: 250,
        sortable: false,
        disableClickEventBubbling: true,
        renderCell: (params) =>
          currentUser.uid != params.value.id ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => deleteAdmin(params.value.id)}
            >
              Delete Admin
            </Button>
          ) : (
            "N / A"
          ),
      },
    ];

  useEffect(() => {
    try {
      var items = [];
      app
        .firestore()
        .collection("users")
        .where("type", "==", userType)
        .get()
        .then(async (snap) => {
          for (var i = 0; i < snap.size; i++) {
            var doc = snap.docs[i];
            var data = doc.data();
            data.id = doc.id;
            data.disabled = data.disabled ?? false;
            data.monthly = data.monthly ?? false;
            data.additionalData = {
              id: doc.id,
              disabled: data.disabled,
              monthly: data.monthly,
            };
            var count = await app
              .firestore()
              .collection("COOForms")
              .where("userId", "==", doc.id)
              .get();
            data.coos = count.size;
            if (!data.discount) data.discount = 0;
            items.push(data);
          }
          setRows(items);
        });
    } catch (e) {
      console.log(e.message);
    }
  }, []);

  if (rows == null)
    return <Loader type="ThreeDots" color="Black" width="50" height="50" />;

  return (
    <div style={{ height: userType == "user" ? 1000 : 500, width: "100%" }}>
      {userType == "user" ? (
        <Title>User List</Title>
      ) : (
        <p style={{ fontSize: "20px" }}>Admin List</p>
      )}
      <DataGrid
        rowHeight={80}
        rows={rows}
        columns={columns}
        pageSize={50}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
