import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";
import { useAlert } from "react-alert";

const AdminPrivateRoute = ({
  permission,
  component: RouteComponent,
  ...rest
}) => {
  const { currentUser, userProfile } = useContext(AuthContext);
  const alert = useAlert();

  if (permission ? !userProfile[permission] : false)
    alert.error("You dont have permission");
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!currentUser ? (
          userProfile.type == "admin" &&
          (permission ? userProfile[permission] : true) ? (
            <RouteComponent {...routeProps} />
          ) : (
            <Redirect to={"/"} />
          )
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

export default AdminPrivateRoute;
