import React, { useEffect, useState, useContext } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Loader from "react-loader-spinner";
import app from "../FirebaseInit";
import { AuthContext } from "../Auth";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { useAlert } from "react-alert";
import * as firebase from "firebase/app";

const CooTable = () => {
  const [rows, setRows] = useState(null);
  const { currentUser } = useContext(AuthContext);
  const alert = useAlert();
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 250,
    },
    {
      field: "SubmittedDate",
      headerName: "Submitted Date",
      width: 200,
      renderCell: (params) => {
        if (params.value)
          return (
            <div>
              {new firebase.default.firestore.Timestamp(
                params.value.seconds,
                params.value.nanoseconds
              )
                .toDate()
                .toLocaleDateString()
                .toString()}
            </div>
          );
        else return <div>-</div>;
      },
    },
    {
      field: "ApprovedDate",
      headerName: "Approved Date",
      width: 200,
      renderCell: (params) =>
        params.value
          ? params.value.toDate().getFullYear().toString() +
            "-" +
            (params.value.toDate().getMonth() + 1).toString() +
            "-" +
            params.value.toDate().getDate().toString()
          : "-",
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
    },
    {
      field: "action",
      headerName: "Actions",
      width: 120,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) =>
        params.value.status === "approved" ? (
          <Button
            component={Link}
            variant="contained"
            style={{
              width: "100%",
              backgroundColor: "orange",
              color: "black",
              fontWeight: "bold",
            }}
            to={`ViewCooForms?id=${params.value.id}`}
          >
            View
          </Button>
        ) : params.value.status === "rejected" ? (
          <Button
            component={Link}
            variant="contained"
            color="secondary"
            style={{
              width: "100%",
              backgroundColor: "darkred",
              color: "white",
            }}
            to={`CooForms?id=${params.value.id}`}
          >
            Resubmit
          </Button>
        ) : params.value.status === "paymentPending" ? (
          <Button
            style={{ width: "100%", backgroundColor: "darkred" }}
            variant="contained"
            color="secondary"
            onClick={async () => {
              var snap = app
                .firestore()
                .collection("COOForms")
                .doc(params.value.id);
              alert.info("Please while we redirect you to the payment page !");
              var getPaymentSession = app
                .functions()
                .httpsCallable("getPaymentSession");
              var res = await getPaymentSession({ id: snap.id });
              await snap.update({ successIndicator: res.data.successIndicator });
              window.Checkout.configure({
                session: {
                  id: res.data["session.id"],
                },
                order: {
                  description: "COO Form Fee",
                },
                interaction: {
                  merchant: {
                    name: "FCCISL",
                  },
                  displayControl: {
                    // you may change these settings as you prefer
                    billingAddress: "HIDE",
                    customerEmail: "HIDE",
                    orderSummary: "SHOW",
                    shipping: "HIDE",
                  },
                },
              });
              return window.Checkout.showPaymentPage();
            }}
          >
            Pay
          </Button>
        ) : (
          <Button style={{ width: "100%" }}>N/A</Button>
        ),
    },
  ];

  useEffect(() => {
    try {
      var items = [];
      app
        .firestore()
        .collection("COOForms")
        .where("userId", "==", currentUser.uid)
        .get()
        .then((snap) => {
          snap.docs.forEach((doc) => {
            var data = doc.data();
            data.id = doc.id;
            data.action = { id: doc.id, status: doc.data().status };
            items.push(data);
          });
          setRows(items);
        });
    } catch (e) {
      console.log(e.message);
    }
  }, []);

  if (rows == null)
    return <Loader type="ThreeDots" color="Black" width="50" height="50" />;

  return (
    <div style={{ height: 1000, width: "60%" }}>
      <center>
        <h2>Your COO Forms</h2>
      </center>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={50}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
};

export default CooTable;
