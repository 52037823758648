import React, { useState, useEffect, useContext } from "react";
import CooFormA from "./Co-FormA";
import CooFormB from "./Co-FormB";
import Nav from "./../Nav";
import { AuthContext } from "../../Auth";
import { useHistory } from "react-router-dom";
import app from "../../FirebaseInit";
import { Box, Typography } from "@material-ui/core";

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default function CooForms() {
  const [currentPage, setCurrentPage] = useState("FormA");
  const [formAData, setFormAData] = useState({ Currency: "LKR" });
  const [formBData, setFormBData] = useState({});
  const [paymentAmount, setPaymentAmount] = useState(null);
  const { currentUser } = useContext(AuthContext);
  let query = useQuery();
  var id = query.get("id");
  let history = useHistory();

  useEffect(() => {
    app
      .firestore()
      .collection("settings")
      .doc("prices")
      .get()
      .then((data) => setPaymentAmount(data.data().coo));
    app
      .firestore()
      .collection("registerForms")
      .doc(currentUser.uid)
      .get()
      .then((snap) => {
        if (!snap.exists) return history.push("/");
        if (snap.data().status != "approved") history.push("/");
      });
    if (id) {
      app
        .firestore()
        .collection("COOForms")
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            if (
              doc.data().userId != currentUser.uid ||
              doc.data().status != "rejected"
            ) {
              return history.push("/");
            }
            setFormAData(doc.data());
            setFormBData(doc.data());
          } else {
            history.push("/");
          }
        });
    }
  }, []);

  return (
    <div className="container">
      <Nav />
      {formAData.rejectReason && (
        <Box m={2}>
          <Typography variant="h4" color="error" align="center" padding="2">
            Reject Reason : {formAData.rejectReason}
          </Typography>
        </Box>
      )}
      {currentPage == "FormA" ? (
        <CooFormA
          data={formAData}
          setData={setFormAData}
          setCurrentPage={setCurrentPage}
        />
      ) : (
        <CooFormB
          formAData={formAData}
          data={formBData}
          setData={setFormBData}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          amount={paymentAmount}
          docId={id}
        />
      )}
    </div>
  );
}
