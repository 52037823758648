import React, { useCallback, useContext, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { grey, orange } from "@material-ui/core/colors";
import Nav from "./Nav";
import { useAlert } from "react-alert";
import { Redirect, withRouter } from "react-router";
import { AuthContext } from "../Auth";
import app from "../FirebaseInit";
import Loader from "react-loader-spinner";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "black",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700],
    },
  },
}))(Button);
const ColorButton2 = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
    "&:hover": {
      backgroundColor: grey[700],
    },
  },
}))(Button);

export default withRouter(function Register({ history }) {
  const classes = useStyles();
  const alert = useAlert();
  const [loading, setLoading] = useState(false);

  const onRegister = useCallback(async (e) => {
    e.preventDefault();
    const {
      confirmPassword,
      userName,
      email,
      tinNo,
      address1,
      address2,
      state,
      city,
      officePhoneNumber,
      password,
      companyName,
      companyRegNo,
      designatedOfficerName,
      contactPersonPhoneNumber,
      titleOfficer,
    } = e.target.elements;
    if (password.value == confirmPassword.value) {
      try {
        setLoading(true);
        var auth = await app
          .auth()
          .createUserWithEmailAndPassword(email.value.trim(), password.value);
        await app
          .firestore()
          .collection("users")
          .doc(auth.user.uid)
          .set({
            userName: userName.value,
            email: email.value,
            tinNo: tinNo.value,
            address1: address1.value,
            address2: address2.value ?? "",
            state: state.value,
            city: city.value,
            officePhoneNumber: officePhoneNumber.value,
            companyName: companyName.value,
            companyRegNo: companyRegNo.value,
            designatedOfficerName: designatedOfficerName.value,
            contactPersonPhoneNumber: contactPersonPhoneNumber.value,
            titleOfficer: titleOfficer.value,
            type: "user",
          });
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err.message);
        alert.error(err.message);
      }
    } else {
      alert.error("Password do not match");
    }
  });

  const { currentUser } = useContext(AuthContext);

  if (currentUser) {
    return <Redirect to={"/"} />;
  }
  return (
    <div>
      <Nav />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            SIGN UP
          </Typography>
          <form className={classes.form} onSubmit={onRegister}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="userName"
                  label="User Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="companyName"
                  label="Company Name"
                  name="companyName"
                  autoComplete="lname"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="email"
                  name="email"
                  variant="outlined"
                  required
                  type="email"
                  fullWidth
                  id="email"
                  label="Email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="RegNo"
                  variant="outlined"
                  required
                  fullWidth
                  id="companyRegNo"
                  label="Company Registration Number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="TinNo"
                  variant="outlined"
                  required
                  fullWidth
                  id="tinNo"
                  label="TIN Number"
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="Address1"
                  variant="outlined"
                  required
                  fullWidth
                  id="address1"
                  label="Address 1"
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="Address 2"
                  variant="outlined"
                  fullWidth
                  id="address2"
                  label="Address 2"
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="State"
                  variant="outlined"
                  required
                  fullWidth
                  id="state"
                  label="State"
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="City"
                  variant="outlined"
                  required
                  fullWidth
                  id="city"
                  label="City"
                />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="OfficePhoneNumber"
                  variant="outlined"
                  required
                  fullWidth
                  id="officePhoneNumber"
                  label="Office Phone Number"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="DesignatedOfficerName"
                  variant="outlined"
                  required
                  fullWidth
                  id="designatedOfficerName"
                  label="Designated Officer's Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="TitleOfficer"
                  variant="outlined"
                  required
                  fullWidth
                  id="titleOfficer"
                  label="Title of the designated Officer"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="ContactMobile"
                  variant="outlined"
                  required
                  fullWidth
                  id="contactPersonPhoneNumber"
                  label="Contact Person Mobile Number"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="password"
                  type="password"
                  variant="outlined"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="ConfPassword"
                  type="password"
                  variant="outlined"
                  required
                  fullWidth
                  id="confirmPassword"
                  label="Confirm Password"
                />
              </Grid>
            </Grid>
            {loading ? (
              <center style={{ padding: "10px" }}>
                <Loader type="ThreeDots" color="Black" width="50" height="50" />
              </center>
            ) : (
              <ColorButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                SIGN UP
              </ColorButton>
            )}
          </form>
        </div>
      </Container>
    </div>
  );
});
