import React, { useState, useEffect, useContext, useCallback } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { green, red } from "@material-ui/core/colors";
import Loader from "react-loader-spinner";
import app from "../../FirebaseInit";
import Nav from "../Nav.js";
import { useAlert } from "react-alert";
import { AuthContext } from "../../Auth";
import * as firebase from "firebase/app";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    flexGrow: "2",
    alignItems: "center",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}))(Button);
const ColorButton2 = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default function PendingRegForm() {
  const classes = useStyles();
  const [data, setData] = useState(null);
  const alert = useAlert();
  const { userProfile } = useContext(AuthContext);
  let query = useQuery();
  var id = query.get("id");
  let history = useHistory();

  useEffect(() => {
    app
      .firestore()
      .collection("registerForms")
      .doc(id)
      .get()
      .then((snap) => {
        if (snap.data().status == "pending") setData(snap.data());
        else history.push("/");
      });
  }, []);

  const onApprove = useCallback(async (e) => {
    e.preventDefault();
    if (userProfile.type != "admin")
      return alert.error("You are not authorized to approve");
    try {
      await app.firestore().collection("registerForms").doc(id).update({
        status: "approved",
        ApprovedDate: firebase.default.firestore.FieldValue.serverTimestamp(),
        ApprovedBy: userProfile.id,
      });
      alert.success("Approved");
      history.push("/");
    } catch (e) {
      console.log(e.message);
      alert.error(e.message);
    }
  });

  const onReject = useCallback(async (e) => {
    e.preventDefault();
    if (userProfile.type != "admin")
      return alert.error("You are not authorized to approve");
    try {
      await app
        .firestore()
        .collection("registerForms")
        .doc(id)
        .update({ status: "rejected" });
      alert.success("Rejected");
      history.push("/");
    } catch (e) {
      console.log(e.message);
      alert.error(e.message);
    }
  });

  if (data == null)
    return (
      <center>
        <Loader type="ThreeDots" color="Black" width="50" height="50" />
      </center>
    );

  return (
    <div>
      <Nav />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            src={require("../../asserts/ComponentTMP_0-image.jpg")}
            width="10%"
            height="10%"
          />

          <Typography component="h1" variant="h5">
            Federation of Chambers of Commerce and Industry of Sri Lanka
          </Typography>
          <Typography component="h1" variant="h5">
            (FCCISL)
          </Typography>
          <Typography component="h1" variant="h5">
            Certificate of Origin - Registration Form
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                Name of the Company
                <TextField
                  disabled
                  required
                  variant="outlined"
                  fullWidth
                  id="CompanyName"
                  name="CompanyName"
                  value={data.CompanyName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Company Registration No
                <TextField
                  disabled
                  required
                  name="CompanyRegistrationNo"
                  variant="outlined"
                  fullWidth
                  id="CompanyRegistrationNo"
                  value={data.CompanyRegistrationNo}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Postal Address
                <TextField
                  disabled
                  required
                  variant="outlined"
                  fullWidth
                  id="Address"
                  name="Address"
                  value={data.Address}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Contact Person
                <TextField
                  disabled
                  required
                  type="number"
                  variant="outlined"
                  fullWidth
                  id="ContactPerson"
                  value={data.ContactPerson}
                  name="ContactPerson"
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                Designation
                <TextField
                  disabled
                  required
                  variant="outlined"
                  fullWidth
                  id="Designation"
                  value={data.Designation}
                  name="Designation"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* empty space */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <h3>Contact Details :</h3>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* empty space */}
              </Grid>
              <Grid item xs={12} sm={6}>
                Telephone Number
                <TextField
                  disabled
                  required
                  type="number"
                  variant="outlined"
                  fullWidth
                  id="Telephone"
                  value={data.Telephone}
                  name="Telephone"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Fax Number
                <TextField
                  required
                  disabled
                  type="number"
                  variant="outlined"
                  fullWidth
                  id="Fax"
                  value={data.Fax}
                  name="Fax"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Email Address
                <TextField
                  required
                  disabled
                  type="email"
                  variant="outlined"
                  fullWidth
                  id="Email"
                  value={data.Email}
                  name="Email"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* empty space */}
              </Grid>
              <Grid item md={12} sm={6}>
                <hr />
              </Grid>

              <Grid item xs={12} sm={6}>
                Type of Product being exported
                <TextField
                  disabled
                  required
                  variant="outlined"
                  fullWidth
                  id="ProductType"
                  name="ProductType"
                  value={data.ProductType}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* empty space */}
              </Grid>

              <Grid item md={12} sm={6}>
                <h3>
                  Please register our company at FCCISL enabling us to obtain
                  the Certificate of Origin.
                </h3>
              </Grid>
              <Grid item xs={12} sm={6}>
                Signature of Authorized Officer with Company Seal
              </Grid>
              <Grid item xs={12} sm={12}>
                <img src={data.companyseal} width="200px" height="200px"></img>
              </Grid>
              <Grid item xs={12} sm={6}>
                <a href={data.companyRegFile} target="_blank">
                  View BR
                </a>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* Empty line */}
              </Grid>

              <Grid item xs={12} sm={6}>
                <ColorButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={onApprove}
                >
                  Approve
                </ColorButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ColorButton2
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={onReject}
                  className={classes.submit}
                >
                  Reject
                </ColorButton2>
              </Grid>
            </Grid>
          </form>
        </div>
      </Container>
    </div>
  );
}
