import React, { useCallback, useContext } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { grey, orange } from "@material-ui/core/colors";
import Nav from "./Nav";
import { useAlert } from "react-alert";
import { Redirect } from "react-router";
import { AuthContext } from "../Auth";
import app from "../FirebaseInit";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "black",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(2, 0, 2),
    width: "200%",
  },
}));
const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(orange[500]),
    backgroundColor: orange[500],
    "&:hover": {
      backgroundColor: orange[700],
    },
  },
}))(Button);
const ColorButton2 = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(grey[500]),
    backgroundColor: grey[500],
    "&:hover": {
      backgroundColor: grey[700],
    },
  },
}))(Button);

export default function Profile({ history }) {
  const classes = useStyles();
  const alert = useAlert();
  const { userProfile } = useContext(AuthContext);

  const sendPasswordResetEmail = async (e) => {
    try {
      await app.auth().sendPasswordResetEmail(userProfile.email);
      alert.success("Password reset link sent !");
    } catch (err) {
      console.log(err.message);
      alert.error(err.message);
    }
  };

  if (!userProfile) {
    return <Redirect to={"/"} />;
  }
  return (
    <div>
      <Nav />
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.form}>
            <Typography component="h1" variant="h4" style={{ paddingBottom: "50px" }}>
              Profile
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  fullWidth
                  id="userName"
                  label="User Name"
                  value={userProfile.userName}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="companyName"
                  label="Company Name"
                  name="companyName"
                  autoComplete="lname"
                  value={userProfile.companyName}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="email"
                  name="email"
                  variant="outlined"
                  type="email"
                  fullWidth
                  id="email"
                  label="Email"
                  value={userProfile.email}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="RegNo"
                  variant="outlined"
                  fullWidth
                  id="companyRegNo"
                  label="Company Registration Number"
                  value={userProfile.companyRegNo}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="TinNo"
                  variant="outlined"
                  fullWidth
                  id="tinNo"
                  label="TIN Number"
                  value={userProfile.tinNo}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6}></Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="Address1"
                  variant="outlined"
                  fullWidth
                  id="address1"
                  label="Address 1"
                  value={userProfile.address1}
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="Address 2"
                  variant="outlined"
                  fullWidth
                  id="address2"
                  label="Address 2"
                  value={userProfile.address2}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="State"
                  variant="outlined"
                  fullWidth
                  id="state"
                  label="State"
                  value={userProfile.state}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="City"
                  variant="outlined"
                  fullWidth
                  id="city"
                  label="City"
                  value={userProfile.city}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="OfficePhoneNumber"
                  variant="outlined"
                  fullWidth
                  id="officePhoneNumber"
                  label="Office Phone Number"
                  value={userProfile.officePhoneNumber}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="DesignatedOfficerName"
                  variant="outlined"
                  fullWidth
                  id="designatedOfficerName"
                  label="Designated Officer's Name"
                  value={userProfile.designatedOfficerName}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="TitleOfficer"
                  variant="outlined"
                  fullWidth
                  id="titleOfficer"
                  label="Title of the designated Officer"
                  value={userProfile.titleOfficer}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="ContactMobile"
                  variant="outlined"
                  fullWidth
                  id="contactPersonPhoneNumber"
                  label="Contact Person Mobile Number"
                  value={userProfile.contactPersonPhoneNumber}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <ColorButton
                onClick={() => sendPasswordResetEmail()}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.button}
              >
                Send Password Reset Email
              </ColorButton>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
}
