import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Loader from "react-loader-spinner";
import Title from "../Title";
import app from "../../../FirebaseInit";
import { Link } from "react-router-dom";
import * as firebase from "firebase/app";

export default function ApprovedCOO() {
  const [rows, setRows] = useState(null);
  const columns = [
    {
      field: "RefNo",
      headerName: "Ref No",
      width: 150,
    },
    {
      field: "ExRegno",
      headerName: "Registration No",
      width: 150,
    },
    { field: "CompanyName", headerName: "Name of the Company", width: 200 },
    {
      field: "SubmittedDate",
      headerName: "Submitted Date",
      width: 200,
      renderCell: (params) => {
        if (params.value)
          return (
            <div>
              {new firebase.default.firestore.Timestamp(
                params.value.seconds,
                params.value.nanoseconds
              )
                .toDate()
                .toLocaleDateString()
                .toString()}
            </div>
          );
        else return <div>-</div>;
      },
    },
    {
      field: "PortLoading",
      headerName: "Port Loading",
      width: 200,
    },
    {
      field: "PortDischarge",
      headerName: "Port Discharge",
      width: 200,
    },
    {
      field: "id",
      headerName: "Actions",
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Link to={`ViewCooForms?id=${params.value}`} style={{}}>
          View
        </Link>
      ),
    },
  ];

  useEffect(() => {
    try {
      var items = [];
      app
        .firestore()
        .collection("COOForms")
        .where("status", "==", "approved")
        .get()
        .then((snap) => {
          snap.docs.forEach((doc) => {
            var data = doc.data();
            data.id = doc.id;
            items.push(data);
          });
          setRows(items);
        });
    } catch (e) {
      console.log(e.message);
    }
  }, []);

  if (rows == null)
    return <Loader type="ThreeDots" color="Black" width="50" height="50" />;

  return (
    <div style={{ height: 1000, width: "100%" }}>
      <Title>Approved COO Forms</Title>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={50}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
