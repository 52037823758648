import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import Loader from "react-loader-spinner";
import Title from "../Title";
import app from "../../../FirebaseInit";
import { Link } from "react-router-dom";

export default function ApprovedReg() {
  const [rows, setRows] = useState(null);
  const columns = [
    {
      field: "RegId",
      headerName: "Registration ID",
      width: 150,
      type: "number",
    },
    { field: "CompanyName", headerName: "Name of the Company", width: 200 },
    {
      field: "CompanyRegistrationNo",
      headerName: "Company Registration No",
      width: 200,
    },
    {
      field: "Address",
      headerName: "Postal Address",
      width: 200,
    },
    {
      field: "ProductType",
      headerName: "Type of Product being exported",
      width: 200,
    },
    {
      field: "Telephone",
      headerName: "Telephone Number",
      width: 200,
    },
    {
      field: "companyRegFile",
      headerName: "BR",
      width: 80,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <a href={params.value} target="_blank">
          View BR
        </a>
      ),
    },
    {
      field: "id",
      headerName: "Actions",
      width: 100,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (params) => (
        <Link
          to={`viewRegisterFrom?id=${params.value.id}`}
          style={{ paddingRight: "10px" }}
        >
          View
        </Link>
      ),
    },
  ];

  useEffect(() => {
    try {
      var items = [];
      app
        .firestore()
        .collection("registerForms")
        .where("status", "==", "approved")
        .get()
        .then((snap) => {
          snap.docs.forEach((doc) => {
            var data = doc.data();
            data.id = doc.id;
            items.push(data);
          });
          setRows(items);
        });
    } catch (e) {
      console.log(e.message);
    }
  }, []);

  if (rows == null)
    return <Loader type="ThreeDots" color="Black" width="50" height="50" />;

  return (
    <div style={{ height: 1000, width: "100%" }}>
      <Title>Approved Registration Forms</Title>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={50}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
