import React, { useContext } from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import app from "../FirebaseInit";
import { AuthContext } from "../Auth";

const useStyles = makeStyles((theme) => ({
  headtypo: {
    color: "orange",
  },
  appbarnav: {
    overflow: "hidden",
    backgroundColor: "black",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  menuItems: {
    float: "right",
  },
  toolbarButtons: {
    marginLeft: "auto",
  },
}));

export default function Nav() {
  const classes = useStyles();
  const { currentUser, userProfile } = useContext(AuthContext);

  return (
    <React.Fragment>
      <CssBaseline />

      <AppBar position="static" className={classes.appbarnav}>
        <Toolbar>
          <Typography>
            <img
              style={{ padding: "20px" }}
              width="150"
              src={require("../asserts/ComponentTMP_0-image.jpg")}
            />
          </Typography>
          <Typography variant="h6" className={classes.headtypo}>
            &nbsp; &nbsp;Federation of Chambers of Commerce and Industry of Sri
            Lanka
          </Typography>
          <div className={classes.toolbarButtons}>
            {!!currentUser ? (
              <div className={classes.menuItems}>
                <Link to="/" className={classes.link}>
                  <Button color="inherit">Home</Button>
                </Link>
                {userProfile?.type != "admin" ? (
                  <Link to="/Profile" className={classes.link}>
                    <Button color="inherit">Profile</Button>
                  </Link>
                ) : null}
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    app.auth().signOut();
                  }}
                  color="inherit"
                >
                  Logout
                </Button>
              </div>
            ) : (
              <div>
                <Link to="/Register" className={classes.link}>
                  <Button color="inherit">Register</Button>
                </Link>
                <Link to="/Login" className={classes.link}>
                  <Button color="inherit">Login</Button>
                </Link>
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>

      {/* End footer */}
    </React.Fragment>
  );
}
