import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Auth";
import app from "../../FirebaseInit";
import { useAlert } from "react-alert";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 200,
  },
  B1: {
    color: "orange",
  },
});

export default function MediaCard() {
  const { currentUser } = useContext(AuthContext);
  const [state, setState] = useState(null);
  const classes = useStyles();
  const alert = useAlert();

  useEffect(() => {
    app
      .firestore()
      .collection("registerForms")
      .doc(currentUser.uid)
      .get()
      .then((snap) => {
        if (snap.exists) {
          setState(snap.data().status);
        }
      });
  }, []);
  return (
    <Link
      to={state == "approved" ? "/CooForms" : '/'}
      style={{ textDecoration: "none" }}
    >
      <Card
        className={classes.root}
        onClick={() =>
          state != "approved"
            ? alert.error("Can not fill this form without an approved registration form")
            : null
        }
      >
        <CardActionArea>
          <CardMedia
            className={classes.media}
            image={require("../../asserts/ID3.png")}
            title="Certificate of Origin"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Certificate of Origin (Form A and B)
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
}
